<script>
import { required, email } from "vuelidate/lib/validators";
import Auth from '@/services/Auth';
import validationMessages from '@/components/validations'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Swal from "sweetalert2";


export default {
  components: {
    validationMessages
  },
  props: ['admin_id'],
  data() {
    return {
      email: '',
      submitted: false,
      showModal: false,
      userEmail: '',
      tryingToEdit: false,
      showLoader: false,
      error: '',
    };
  },
  validations: {
    email: {
      required,
      email
    },
  },

  methods: {
    async forgotPassword() {

      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      }

      try {

        const response = await Auth.requestForgotPassword({
          email: this.email,
        });

        const res = response.data.data || false;
        const error = response.data.error || '';

        if (res && !error) {
          // Display success message with Swal
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${response.data.data.message}`,
            timer: 5000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          this.closeModal();
        } else {
          // Display error message with Swal
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to change user password',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        this.error = error.response?.data?.error || "Unknown error occurred";

        // Display the error message with Swal
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: this.error,
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } finally {
        this.tryingToEdit = false;
      }
    },

    closeModal() {
      this.showModal = false;
      this.email = '';
      this.tryingToEdit = false;
      this.submitted = false;
    },
    resetProps() {
      this.showModal = false;
      this.email = '';
      this.tryingToEdit = false;
      this.submitted = false;
    }
  }
};
</script>


<template>
  <b-modal
      v-model="showModal"
      id="forgot_password"
      title="Change Password"
      title-class="font-18"
      size="lg" centered
      data-backdrop="static"
      @hidden='resetProps()'
      data-keyboard="false">
    <form @submit.prevent="forgotPassword" v-if="!showLoader">
      <div class="row">
        <div class="col-md-12 ">
          <b-form-group label="Email" label-for="formrow-email-input" class="mb-3">
            <b-input-group>
              <b-form-input
                  v-model.trim="email"
                  placeholder="Enter your email"
                  id="email"
                  aria-describedby="email-feedback"
                  :class="{
                              'is-invalid': submitted && $v.email.$error,
                            }"
              >
              </b-form-input>
            </b-input-group>
            <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.email"></validationMessages>
          </b-form-group>
        </div>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>

    <template v-slot:modal-footer>
      <b-button variant="primary" @click="forgotPassword" :disabled="tryingToEdit || showLoader">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Send</b-button>
    </template>
  </b-modal>
</template>

