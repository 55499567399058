<script>
    import { required, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
    import Auth from '@/services/Auth';
    import validationMessages from '@/components/validations'
    import "vue-form-wizard/dist/vue-form-wizard.min.css";

    export default {
        components:{
              validationMessages
        },
        props: ['admin_id'],
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                password: '',
                password_confirmation: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                showLoader: false,
                submittedPasswordChange: false,
                new_password: '',
                confirm_password: '',
                current_password: '',
                tenant: process.env.VUE_APP_TENANT,
                showCurrentPassword: false,
                showNewPassword: false,
                showConfirmPassword: false,
            };
        },
        validations: {
           current_password:{
               required
           },
           new_password: {
               required,
               valid: function(value) {
                   const containsUppercase = /[A-Z]/.test(value)
                   const containsLowercase = /[a-z]/.test(value)
                   const containsNumber = /[0-9]/.test(value)
                   const containsSpecialCharacter = /[!#$%&'()*+,-.:;<=>?@^_`{|}~]/.test(value);
                   return containsUppercase && containsLowercase && containsNumber && containsSpecialCharacter
               },
               minLength: minLength(8),
               maxLength: maxLength(20),
           },
           confirm_password: {
               required,
               sameAsPassword: sameAs('new_password')
           },
        },

        methods: {
            async changeAdminPassword(){
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    //Send admin_id only if you want to change pass for other user
                    let user_id = localStorage.getItem('user_id');

                    if(this.admin_id){
                        user_id = this.admin_id
                    }

                    try {
                        await Auth.changeAdminPassword({
                            user_id: user_id,
                            current_password: this.current_password,
                            new_password: this.new_password,
                            confirm_password: this.confirm_password,
                            csrf_token: this.csrf_token,
                            force_password_change:0
                        }).then((response) => {
                            const res   = response.data.data  ? response.data.data  : false;
                            const error = response.data.error ? response.data.error : '';
                            if(res && error==''){
                                this.successmsg("User password changed successfully");
                                this.closeModal();
                                if(this.$route.name == 'login'){
                                    this.$router.push({name: "home"});
                                }

                                this.new_password = '';
                                this.confirm_password = '';
                                this.current_password = '';
                            }else{
                                this.failedmsg("Failed to change user password")
                            }

                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.tryingToEdit = false;
                        })
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("User not saved")
                    }
                }
                this.tryingToEdit = false;
            },

            closeModal() {
                this.showModal = false;
                this.password = ''
                this.password_confirmation = ''
                this.tryingToEdit = false
                this.submitted = false
            },
            toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="change_password" title="Change User Password" title-class="font-18" size="lg" centered data-backdrop="static" data-keyboard="false">
             <form @submit.prevent="changeAdminPassword" v-if="!showLoader">
                <div class="row">
                    <div class="col-md-12 ">
                        <b-form-group label="Current Password" label-for="formrow-current_password-input" class="mb-3">
                            <b-input-group>
                            <b-form-input
                                    v-model.trim="current_password"
                                    :type="showCurrentPassword ? 'text' : 'password'"
                                    id="current_password"
                                    aria-describedby="current_password-feedback"
                                    :class="{
                              'is-invalid': submitted && $v.current_password.$error,
                            }"
                            >
                            </b-form-input>
                            <b-input-group-append>
                            <b-button variant="btn btn-outline-secondary" @click="toggleShowCurrentPassword">
                              <i :class="showCurrentPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </b-button>
                          </b-input-group-append>
                            </b-input-group>
                            <validationMessages v-if="submitted" :fieldName="'Current Password'" :validationName="$v.current_password"></validationMessages>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 ">
                        <b-form-group label="New Password" label-for="formrow-new_password-input" class="mb-3">
                            <b-input-group>
                            <b-form-input
                                    v-model.trim="new_password"
                                    :type="showNewPassword ? 'text' : 'password'"
                                    id="new_password"
                                    aria-describedby="new_password-feedback"
                                    :class="{
                              'is-invalid': submitted && $v.new_password.$error,
                            }"
                            >
                            </b-form-input>
                            <b-input-group-append>
                            <b-button variant="btn btn-outline-secondary" @click="toggleShowNewPassword">
                              <i :class="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </b-button>
                          </b-input-group-append>
                            </b-input-group>
                            <validationMessages v-if="submitted" :fieldName="'New Password'" :validationName="$v.new_password"></validationMessages>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 ">
                        <b-form-group label="Confirm Password" label-for="formrow-confirm_password-input" class="mb-3">
                            <b-input-group>
                            <b-form-input
                                    v-model.trim="confirm_password"
                                    :type="showConfirmPassword ? 'text' : 'password'"
                                    id="confirm_password"
                                    aria-describedby="confirm_password-feedback"
                                    :class="{
                              'is-invalid': submitted && $v.confirm_password.$error,
                            }"
                            >
                            </b-form-input>
                            <b-input-group-append>
                            <b-button variant="btn btn-outline-secondary" @click="toggleShowConfirmPassword">
                              <i :class="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </b-button>
                          </b-input-group-append>
                            </b-input-group>
                            <validationMessages v-if="submitted" :fieldName="'Confirm Password'" :validationName="$v.confirm_password"></validationMessages>
                        </b-form-group>
                    </div>
                </div>
            </form>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>

        <template v-slot:modal-footer>
            <b-button variant="primary" @click="changeAdminPassword" :disabled="tryingToEdit || showLoader">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Change</b-button>
        </template>
    </b-modal>
</template>

<style>

</style>